import React from "react";
import PropTypes from 'prop-types';
import URI from 'urijs';

import { UrlConsumer } from '../context/UrlContext';

const PagerItem = (props) => {
    var css = props.linkClass;

    if (!css) {
        css = 'page-item';
        if (props.currentPage === props.page)
            css += ' active';
        if (props.page === 1)
            css += ' first';
    }

    function remap(urlStr) {
        var url = new URI(urlStr);
        url.setSearch('page', props.page);
        return url.path() + '?' + url.query();
    }

    return (
        <UrlConsumer>
            {(url) => (
                <li className={css}>
                    <a href={remap(url)} className="page-link" aria-current={props.currentPage === props.page ? 'page' : null} onClick={(e) => { e.preventDefault(); props.currentPage !== props.page ? props.onPageChange(props.page) : null; }}>
                        <If condition={!props.hidePage && !props.children}>
                            <span>{props.page}</span>
                        </If>
                        <If condition={props.children}>
                            {props.children}
                        </If>
                    </a>
                </li>
            )}
        </UrlConsumer>
    );
};

PagerItem.propTypes = {
    currentPage: PropTypes.number.isRequired,
    hidePage: PropTypes.bool,
    linkClass: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired
};

export default PagerItem;
