import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';
import URI from 'urijs';
import { I18nProvider, I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { getGlobalStore } from '../stores/GlobalStore';
import SerieSearchFields from './SerieSearchFields';
import SerieList from './SerieList';
import LoadingIndicator from '../LoadingIndicator';
import Pager from '../shared/Pager';
import { UrlProvider } from '../context/UrlContext';

const loadingMsgDelay = 300;

class Serie extends React.Component {
    globalStore = getGlobalStore();

    constructor(props) {
        super(props);

        this.refList = React.createRef();

        // Initales Suchresultat laden
        this.state = {
            isLoading: false,
            searchParameters: props.searchResult.searchParameters,
            totalRecordCount: props.searchResult.totalRecordCount,
            items: props.searchResult.items,
            searchFieldData: props.searchResult.searchFieldData
        };
    }

    onPageChange = (page) => {
        this.setState(prevState => ({
            searchParameters: { ...prevState.searchParameters, currentPage: page }
        }), () => {
            this.executeSearch(false);
            this.scrollToView();
        });
    }

    onFilterChange = (newFilterValues) => {
        var filterValues = { ...this.state.searchParameters.filterValues, ...newFilterValues };

        this.setState(prevState => ({
            searchParameters: {
                ...prevState.searchParameters,
                filterValues: filterValues,
                currentPage: 1
            }
        }), () => this.executeSearch(true));
    }

    executeSearch = () => {
        const data = { ...this.state.searchParameters };
        //this.setState({ isLoading: true });
        var timer = setTimeout(() => { this.setState({ isLoading: true }); }, loadingMsgDelay);
        axios({
            method: 'post',
            url: '/api/seriesearch/search',
            headers: { 'accept-language': this.globalStore.languageId },
            data: data
        }).then(res => {
            var newState = {
                searchParameters: res.data.searchParameters,
                totalRecordCount: this.props.limited ? res.data.items.length : res.data.totalRecordCount,
                items: res.data.items,
                searchFieldData: res.data.searchFieldData
            };

            clearTimeout(timer);
            this.setState(newState, () => this.updateQueryParameters(true));
        }).finally(() => {
            clearTimeout(timer);
            this.setState({ isLoading: false });
        });
    };

    updateQueryParameters = (clearHash) => {
        var uri = new URI();
        var query = uri.query(true);
        query.page = this.state.searchParameters.currentPage > 1 ? this.state.searchParameters.currentPage : undefined;
        Object.keys(this.state.searchParameters.filterValues).map(key => {
            var v = this.state.searchParameters.filterValues[key];
            if (Array.isArray(v)) {
                query[key.toLowerCase()] = v.length ? v : undefined;
            } else if (typeof v === 'boolean') {
                query[key.toLowerCase()] = v === true ? v : undefined;
            } else if (v !== null && typeof v.getMonth === 'function') {
                query[key.toLowerCase()] = v.toISOString();
            } else {
                query[key.toLowerCase()] = v !== null && v !== undefined ? v : undefined;
            }
        });
        uri.setQuery(query);
        if (clearHash) {
            uri.hash('');
        }
        window.history.replaceState(null, '', uri.href());
    }

    scrollToView = () => {
        this.refList.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    }

    searchfieldOnSelectionChanged = (newFilterValues) => {
        var filterValues = { ...this.state.searchParameters.filterValues, ...newFilterValues };
        this.setState(prevState => ({ searchParameters: { ...prevState.searchParameters, filterValues: filterValues, currentPage: 1 } }), () => this.executeSearch(true));
    }

    searchfieldOnResetFilters = () => {
        var filters = { ...this.state.searchParameters.filterValues };
        Object.keys(filters).map(key => filters[key] = null);
        this.setState(prevState => ({ searchParameters: { ...prevState.searchParameters, filterValues: filters, start: 0 } }), () => this.executeSearch(true));
    }

    render() {
        return (
            <I18nProvider language={this.globalStore.languageId} locales={this.globalStore.uiCulture} catalogs={global.Catalogs}>
                <If condition={!this.props.hideSearch}>
                    <div className="mb-4 wrapper-searchfields p-3">
                        <SerieSearchFields
                            filterValues={this.state.searchParameters.filterValues}
                            searchFieldData={this.state.searchFieldData}
                            onSelectionChanged={this.searchfieldOnSelectionChanged}
                            onResetFilters={this.searchfieldOnResetFilters}
                        />
                    </div>
                </If>

                <Choose>
                    <When condition={this.state.items && this.state.items.length > 0}>
                        <div className="serie-list">
                            <div ref={this.refList}>
                                <UrlProvider value={this.props.defaultUrl}>
                                    <SerieList items={this.state.items} />
                                    <Pager total={this.state.totalRecordCount} pageSize={this.state.searchParameters.pageSize} currentPage={this.state.searchParameters.currentPage} onPageChange={this.onPageChange} />
                                </UrlProvider>
                            </div>
                            <If condition={this.state.isLoading}>
                                <LoadingIndicator />
                            </If>
                        </div>
                    </When>
                    <Otherwise>
                        <p><Trans id="serien.keinResultat">Leider konnten keine Serien gefunden werden.</Trans></p>
                    </Otherwise>
                </Choose>
            </I18nProvider>
        );
    }
}

Serie.propTypes = {
    defaultUrl: PropTypes.string.isRequired,
    searchResult: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Serie;
