//https://mattiamanzati.github.io/mobx-state-tree-playground/index.html
import { types } from "mobx-state-tree";
const GlobalStore = types
    .model('GlobalStore', {
        languageId: types.optional(types.string, 'de'),
        uiCulture: types.optional(types.string, 'de-CH')
    });

let store = null;

const getGlobalStore = () => {
    if (!store || (typeof window === 'undefined' && global.__SERVER__)) {
        let initialData = {};
        if (typeof window !== 'undefined') {
            if (window.__GlobalStore_InitialData !== undefined) {
                initialData = window.__GlobalStore_InitialData;
            }
        } else if (typeof global.__GlobalStore_InitialData !== undefined) {
            if (global.__GlobalStore_InitialData !== undefined) {
                initialData = global.__GlobalStore_InitialData;
            }
        }

        store = GlobalStore.create(initialData);
    }

    return store;
};

export { getGlobalStore, GlobalStore };