import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';

const LoadingIndicator = (props) => {
    return (
        <div className="loading-mask">
            <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        </div>
    );
};

export default LoadingIndicator;