import React from "react";
import PropTypes from 'prop-types';
import { I18n } from "@lingui/react";
import { Trans } from '@lingui/macro';

const SerieListItem = (props) => {
    return (
        <I18n>
            {({ i18n }) => (
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="serie-list-item mb-4 d-flex flex-column" key={props.item.serieId}>
                        <Choose>
                            <When condition={props.item.imgSrc}>
                                <div style={{ height: '220px' }} className="serie-list-image-wrapper text-center">
                                    <a href={props.item.serieDetailUrl}><img src={props.item.imgSrc} alt={props.item.name} className="mb-2 p-3 img-fluid" /></a>
                                </div>
                            </When>
                            <Otherwise>
                                <div style={{height: '220px'}} className="serie-list-image-wrapper">
                                    <img src="/Content/images/bottle_dummy.jpg" className="mb-2 img-fluid" />
                                </div>
                            </Otherwise>
                        </Choose>

                        <div className="text-wrapper p-3 flex-grow-1 d-flex justify-content-between flex-column">
                            <strong className="text-primary mb-2">{props.item.name}</strong>
                            <div className="artikel-detail"><a href={props.item.serieDetailUrl}><Trans id="serien.detail">Detail</Trans></a></div>
                        </div>
                    </div>
                </div>
            )}
        </I18n>
    );
};

SerieListItem.propTypes = {
    item: PropTypes.shape({
        fachpersonId: PropTypes.number,
        name: PropTypes.string.isRequired,
        anschrift: PropTypes.string,
        telefon: PropTypes.string,
        fax: PropTypes.string,
        email: PropTypes.string,
        websiteUrl: PropTypes.string,
        zertifikate: PropTypes.arrayOf(PropTypes.string),
        qualifikationen: PropTypes.string,
        facharzttitel: PropTypes.arrayOf(PropTypes.string),
        regionen: PropTypes.arrayOf(PropTypes.string),
        muttersprachen: PropTypes.arrayOf(PropTypes.string),
        sprachen: PropTypes.arrayOf(PropTypes.string),
        verfuegbarkeiten: PropTypes.arrayOf(PropTypes.string)
    }).isRequired
};

export default SerieListItem;
