import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { I18n } from "@lingui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";
import { Input, CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import Enumerable from 'linq';
import { debounce } from "debounce";


class SearchFieldMultiselect extends React.Component {
    constructor(props) {
        super(props);
        this.dropdownToggleRef = React.createRef();
    }

    state = {
        selectedValues: [],
        selectedValuesFromProps: null,
        items: this.props.items,
        isOpen: false,
        searchTextboxValue: '',
        searchQuery: '',
        selectionIsModified: false
    }

    static getDerivedStateFromProps(props, state) {
        var result = { items: props.items };

        //console.log('getDerivedStateFromProps ' + props.placeholder, props.selectedValues, state.selectedValuesFromProps);
        if (props.selectedValues !== state.selectedValuesFromProps) {
            result.selectedValues = props.selectedValues || [];
            result.selectedValuesFromProps = props.selectedValues;
        }

        return result;
    }

    componentDidMount = () => {
        const node = ReactDOM.findDOMNode(this.dropdownToggleRef.current);
        if (node && node.offsetWidth) {
            this.setState({ dropdownMinWidth: node.offsetWidth });
        }
    }

    dropdownOnToggle = () => {
        var open = !this.state.isOpen;
        if (open) {
            this.setState({ selectionIsModified: false });
            document.body.classList.add('multiselect-open');
        } else {
            document.body.classList.remove('multiselect-open');
            if (this.state.selectionIsModified) {
                this.props.onSelectionChanged(this.state.selectedValues);
            }
        }
        this.setState({ isOpen: open });
    }

    searchTextboxOnChange = (e) => {
        this.setState({ searchTextboxValue: e.target.value }, () => this.setSearchQueryDebounced(this.state.searchTextboxValue));
    }

    setSearchQueryDebounced = debounce((v) => this.setState({ searchQuery: v }), 300)

    resetSearchQuery = () => {
        this.setState({ searchQuery: '', searchTextboxValue: '' });
    }

    toggleSelected = (value) => {
        if (this.state.selectedValues.includes(value)) {
            this.setState(prevState => ({ selectionIsModified: true, selectedValues: prevState.selectedValues.filter(p => p !== value) }));
        } else {
            this.setState(prevState => ({ selectionIsModified: true, selectedValues: [...prevState.selectedValues, value] }));
        }
    }

    clearSelection = () => {
        this.setState({ selectionIsModified: true, selectedValues: [] });
    }

    render() {
        //console.log('render ' + this.props.placeholder, this.state.selectedValues, this.state.selectedValuesFromProps);
        const query = this.state.searchQuery.toLowerCase();
        const filteredItems = this.state.searchQuery ? Enumerable.from(this.state.items).where(p => p.text.toLowerCase().indexOf(query) > -1).orderBy(p => p.text).toArray() : this.state.items;
        const selection = this.state.selectedValues.length > 0 ? Enumerable.from(this.state.items).where(p => this.state.selectedValues.includes(p.value)).select(p => p.text).toArray().join(', ') : '';

        return (
            <Dropdown className="multiselect" isOpen={this.state.isOpen} toggle={this.dropdownOnToggle}>
                <DropdownToggle color="light" className="w-100 text-left" ref={this.dropdownToggleRef}>
                    <FontAwesomeIcon icon={faCaretDown} className="float-right" style={{ marginTop: '4px' }} />
                    <Choose>
                        <When condition={this.state.selectedValues.length > 0}>
                            <div className="text-truncate">{selection}</div>
                        </When>
                        <When condition={this.props.placeholder}>
                            <div className="text-truncate">{this.props.placeholder}</div>
                        </When>
                    </Choose>
                </DropdownToggle>

                <DropdownMenu style={{ minWidth: this.state.dropdownMinWidth ? this.state.dropdownMinWidth : 'unset' }}>
                    <DropdownItem header tag="div" disabled className="header pl-3 pr-3">
                        {this.props.placeholder}
                    </DropdownItem>

                    <If condition={this.props.enableSearch}>
                        <DropdownItem header tag="div" toggle={false} className="searchbox pl-3 pr-3">
                            <InputGroup size="sm">
                                <InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText></InputGroupAddon>
                                <I18n>
                                    {({ i18n }) => (
                                        <Input
                                            type="text"
                                            value={this.state.searchTextboxValue}
                                            onChange={this.searchTextboxOnChange}
                                            placeholder={i18n._(t('multiselect.suchbegriff')`Suchbegriff`)}
                                            onFocus={(e) => e.target.select()}
                                            disabled={this.props.disabled}
                                        />
                                    )}
                                </I18n>
                                <If condition={this.state.searchQuery}>
                                    <InputGroupAddon addonType="append"><Button outline color="form" disabled={this.props.disabled} onClick={this.resetSearchQuery}><FontAwesomeIcon icon={faTimes} /></Button></InputGroupAddon>
                                </If>
                            </InputGroup>
                        </DropdownItem>
                        <DropdownItem divider className="mb-0" />
                    </If>

                    <div className="inner-scroller" style={{ paddingTop: this.props.enableSearch ? null : '9px' }}>
                        <Choose>
                            <When condition={this.state.searchQuery && filteredItems.length === 0}>
                                <DropdownItem tag="div" className="text-muted">
                                    <Trans id="multiselect.noSearchResults">Keine Einträge<br />für diesen Suchbegriff!</Trans>
                                </DropdownItem>
                            </When>
                            <Otherwise>
                                <If condition={filteredItems}>
                                    <For each="item" of={filteredItems}>
                                        <DropdownItem toggle={false} key={this.props.name + '-' + item.value} className="pl-3" onClick={() => this.toggleSelected(item.value)} active={this.state.selectedValues.includes(item.value)} disabled={item.disabled}>
                                            <span style={{ width: '30px' }} className="d-inline-block">
                                                <If condition={this.state.selectedValues.includes(item.value)}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </If>
                                            </span>
                                            <span style={{ paddingLeft: item.level > 0 && !this.state.searchQuery ? item.level * 20 : null }}>{item.text}</span>
                                        </DropdownItem>
                                    </For>
                                </If>
                            </Otherwise>
                        </Choose>
                    </div>

                    <DropdownItem divider className="mt-0" />

                    <DropdownItem header tag="div" toggle={false} className="pl-3 pr-3">
                        <Button color="primary" className="float-right" size="sm" onClick={this.dropdownOnToggle}><FontAwesomeIcon icon={faTimes} className="mr-2" /><Trans id="multiselect.schliessen">Schliessen</Trans></Button>
                        <Button color="secondary" size="sm" disabled={this.state.selectedValues.length === 0} onClick={this.clearSelection}><FontAwesomeIcon icon={faTrashAlt} className="mr-2" /><Trans id="multiselect.resetSelection">Löschen</Trans></Button>
                    </DropdownItem>

                </DropdownMenu>
            </Dropdown>
        );
    }
}

SearchFieldMultiselect.propTypes = {
    disabled: PropTypes.bool,
    enableSearch: PropTypes.bool,
    items: PropTypes.array,
    name: PropTypes.string,
    onSelectionChanged: PropTypes.func,
    placeholder: PropTypes.string,
    selectedValues: PropTypes.array
};

export default SearchFieldMultiselect;