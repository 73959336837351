import $ from 'jquery';
import 'magnific-popup';

//Disable Caching for AJAX Requests
$.ajaxSetup({ cache: false });

//Navigation
$(function () {
    $('.d-nav-menu-toplevel').navigationDesktopMenu({
        collapsibleMenuStartLevel: 1,
        openToplevelWithClick: false
    });
    $('.m-nav').navigationMobile();
});

$(function () {
    $('.magnific-popup').each(function () {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Bild #%curr% laden...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                titleSrc: function (item) {
                    if (item.el.attr('title'))
                        return item.el.attr('title');
                    else
                        return '';
                }
            }
        });
    });
});

$(window).on("load", function () {
    homeSliderNavHandler();
});

$(window).on('resize', function () {
    homeSliderNavHandler();
});

function homeSliderNavHandler() {

    if ($(".carouselCard").length) {

        if ($(window).width() <= 768) {
            $("#carouselMultiItem").css('height', 'auto');
        }
        else {
            var maxHeight = 0;
            $(".carouselCard").each(function () {
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });
            if (maxHeight < 100)
                maxHeight = 700;

            $("#carouselMultiItem").height(parseInt(maxHeight + 60));
        }
    }

}