import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";

global.React = React;
global.ReactDOM = ReactDOM;

import styles from './styles/_styles.scss';

import jquery from 'jquery';
window.$ = jquery;
window.jQuery = jquery;

//CMS AJAX Library
import './js/cms.ajax';

//import 'bootstrap';
//import './js/bootstrap';
//import './js/bootstrap/dropdown';
//import './js/bootstrap/modal';
//import './js/bootstrap/button';
//import './js/bootstrap/collapse';
//import './js/bootstrap/carousel';
import 'bootstrap/js/src/carousel';

import Components from './components';
global.Components = Components;
//console.log(Components);

//Konfiguration Übersetzungen
import catalogDe from './components/locales/de/messages';
import catalogFr from './components/locales/fr/messages';
global.Catalogs = { de: catalogDe, fr: catalogFr };

import './js/navigation-desktop-menu';
import './js/navigation-mobile';
import './js/init';

//font awesome import symbols
import { library, dom } from '@fortawesome/fontawesome-svg-core';

//import { faSearch, faUser, faLock, faCheck, faChevronRight, faSpinner, faPlus, faPencil, faArrows, faCopy, faUnlock, faTrash, faBars, faSignIn, faSignOut, faTimes, faChevronCircleRight, faChevronCircleLeft, faCircle } from '@fortawesome/pro-solid-svg-icons';

import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows';
import { faBatteryBolt } from '@fortawesome/pro-solid-svg-icons/faBatteryBolt';
import { faWeightHanging } from '@fortawesome/pro-solid-svg-icons/faWeightHanging';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faUnlock } from '@fortawesome/pro-solid-svg-icons/faUnlock';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons/faSignIn';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons/faChevronCircleRight';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronCircleLeft';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';

import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

library.add(
    faSearch,
    faUser,
    faLock,
    faCheck,
    faChevronRight,
    faSpinner,
    faPlus,
    faPencil,
    faArrows,
    faBatteryBolt,
    faWeightHanging,
    faCopy,
    faUnlock,
    faTrash,
    faBars,
    faSignIn,
    faSignOut,
    faTimes,
    faChevronCircleRight,
    faChevronCircleLeft,
    faCircle,

    faFacebook,
    faYoutube,
    faInstagram
);
dom.watch();
//console.log(library);
