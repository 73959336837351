import React from "react";
import PropTypes from 'prop-types';
import SerieListItem from './SerieListItem';

const SerieList = (props) => {
    return (
        <div className="serie-items row">
            <For each="item" index="index" of={props.items}>
                <SerieListItem key={item.serieId} item={item} />
            </For>
        </div>
    );
};

SerieList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SerieList;
