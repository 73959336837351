import React from "react";
import PropTypes from 'prop-types';
import URI from 'urijs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

import PagerItem from './PagerItem';
import { UrlConsumer } from '../context/UrlContext';

const Pager = (props) => {
    if (props.total <= props.pageSize) {
        return null;
    }

    var totalPages = Math.ceil(props.total / props.pageSize);
    var isFirstPage = props.currentPage <= 1;
    var isLastPage = props.currentPage >= totalPages;
    var prevPage = props.currentPage - 1;
    var nextPage = props.currentPage + 1;

    var prevCss = 'page-item';
    if (isFirstPage) {
        prevCss += ' disabled';
        prevPage = 1;
    }

    var nextCss = 'page-item';
    if (isLastPage) {
        nextCss += ' disabled';
        nextPage = totalPages;
    }

    var pages = [];
    var minPage = 1;
    var maxPage = 10;
    if (props.currentPage > 6) {
        minPage = props.currentPage - 5;
        maxPage = minPage + 9;
    }
    if (maxPage > totalPages) {
        maxPage = totalPages;
    }
    if (props.currentPage > totalPages - 4) {
        if (totalPages - 9 >= 1)
            minPage = totalPages - 9;
        else
            minPage = 1;
    }

    for (var i = minPage; i <= maxPage; i++) {
        pages.push(i);
    }


    function remap(urlStr, page) {
        var url = new URI(urlStr);
        url.setSearch('page', page);
        return url.path() + '?' + url.query();
    }

    return (
        <UrlConsumer>
            {(url) => (
                <nav aria-label="Pagination">
                    <ul className="pagination justify-content-center">
                        <PagerItem page={prevPage} currentPage={props.currentPage} linkClass={prevCss} onPageChange={props.onPageChange}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </PagerItem>
                        <For each="page" of={pages}>
                            <PagerItem key={page} page={page} currentPage={props.currentPage} onPageChange={props.onPageChange} />
                        </For>
                        <PagerItem page={nextPage} currentPage={props.currentPage} linkClass={nextCss} onPageChange={props.onPageChange}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </PagerItem>
                    </ul>
                </nav>
            )}
        </UrlConsumer>
    );
};

Pager.propTypes = {
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
};

export default Pager;
