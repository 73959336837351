/********************************************************************************************
* 
* Plugin:			navigationDesktopMenu
* Beschreibung:		Steuerung der Navigation Desktop Menu
* 
* ******************************************************************************************/
import jQuery from 'jquery';

(function ($, window, document, undefined) {
	//Plugin Name
	var pluginName = 'navigationDesktopMenu';

	//Defaults
	var defaults = {
		collapsibleMenuStartLevel: 0,
		slideAnimationDuration: 200,
		openToplevelWithClick: false
	};

	// Konstruktur
	function NavigationDesktopMenu(el, options) {
		this.element = el instanceof jQuery ? el : $(el);
		this.options = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = pluginName;
		this.init();
	}

	// Methoden und Eigenschaften
	$.extend(NavigationDesktopMenu.prototype, {
		// Root jquery element
		element: null,

		// Optionen
		options: null,

		// Komponente initialiseren
		init: function () {
			var that = this;
			var isTouchDevice = that.isTouchDevice() || that.options.openToplevelWithClick;

			// CSS Klasse nav-menu setzen
			that.element.find('>li[data-target]').each(function () {
				$(this).addClass('nav-menu');
				if (that.options.collapsibleMenuStartLevel > 0)
					that.addMenuCls($('#' + $(this).data('target') + ' > ul'), that.options.collapsibleMenuStartLevel, 1);
			});

			// SPAN für Menu Icon erstellen
            if (isTouchDevice) {
				//that.element.find('.nav-menu').prepend('<span class="menu-arrow"></span>');
                that.element.find('.nav-menu a[href!="#"]').parent('li').prepend('<span class="menu-arrow"></span>');
			}
			$('.d-nav-menu-items .nav-menu').prepend('<span class="menu-arrow"></span>');

			if (isTouchDevice) {
				// Event Listener Menu Arrow
				that.element.find('>li.nav-menu>.menu-arrow').on('click', function (event) {
					event.preventDefault();
					var menu = $(this).closest('.nav-menu[data-target]');
					if (menu.hasClass('open')) {
						that.hideAllMenus();
					} else {
						that.showMenu(menu);
					}
				});

				//Event Listener Click A
				that.element.find('>li.nav-menu>a[href="#"]').on('click', function (event) {
					event.preventDefault();
					var menu = $(this).closest('.nav-menu[data-target]');
					if (menu.hasClass('open')) {
						that.hideAllMenus();
					} else {
						that.showMenu(menu);
					}
				});
			} else {
				// Event Listener Mouse Over Menu
				that.element.find('>li.nav-menu>a').on('mouseenter', function (event) {
					event.preventDefault();
					var menu = $(this).closest('.nav-menu[data-target]');
					if (!menu.hasClass('open'))
						that.showMenu(menu);
				});

				//Event Listener Mouse Move -> Alle Menus schliessen, falls ausserhalb
				$(document).on('mousemove', function (event) {
					var $target = $(event.target);
					if (!$target.closest('.nav-menu.open').length && !($target.closest('.d-nav-menu-items:visible').length)) {
						that.hideAllMenus();
					}
				});
			}

			//Event Listener Mouse Click outside -> Alle Menus schliessen
			$(document).on('click', function (event) {
				var $target = $(event.target);
				if (!$target.closest('.nav-menu.open').length && !($target.closest('.d-nav-menu-items:visible').length)) {
					that.hideAllMenus();
				}
			});

			//Event Listener Mouse Click Submenu
			$('.d-nav-menu-items li.nav-menu > .menu-arrow').on('click', function () {
				var $this = $(this);
				var li = $this.closest('li.nav-menu');
				var ul = li.find('>ul');
				if (li.hasClass('open')) {
					ul.slideUp(that.options.slideAnimationDuration, function () { li.removeClass('open'); });
				} else {
					ul.slideDown(that.options.slideAnimationDuration, function () { li.addClass('open'); });
				}
			});

			//Event Listener Mouse Click Submenu Link
			$('.d-nav-menu-items li.nav-menu > a[href="#"]').on('click', function (event) {
				event.preventDefault();
				$(this).parent().find('>.menu-arrow').trigger('click');
			});
		},

		showMenu: function (menuItem) {
			this.hideAllMenus();
			var menu = $('#' + menuItem.data('target'));

			menuItem.addClass('open');

			menu.width('');
            menu.find('li.nav-menu>ul').css('display','block');
			var w = menu.width();
            menu.find('li.nav-menu>ul').css('display', '');
			menu.width(w);

			var h = menu.height();
			menu.css('top', menuItem.position().top + menuItem.height());
			menu.css('left', menuItem.position().left);
			menu.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
				menu.height('');
			});
			menu.height(0).addClass('visible').height(h);
		},

		hideAllMenus: function (el) {
            this.element.find('>li.nav-menu.open').each(function () {
                var $this = $(this);
                $this.removeClass('open');
                $('#' + $this.data('target')).removeClass("visible");
            });
		},

		// CSS Klasse für Menu-Button hinzufügen
		addMenuCls: function ($ul, startLevel, currentLevel) {
			var that = this;
			var arrLi = $ul.find('> li');
            arrLi.each(function (idx, li) {
                var $li = $(li);
                var $subUl = $li.find('> ul');

                if ($subUl.length > 0) {
                    if (currentLevel >= startLevel) {
                        $li.addClass('nav-menu');

                        //Nodes mit Klasse active öffnen
                        if ($li.hasClass('active')) {
                            $li.addClass('open');
                        }

                    }
                    that.addMenuCls($subUl, startLevel, currentLevel + 1);
                }
            });
		},

		// Ermittlung, ob es sich um ein Touch-Device handelt
		isTouchDevice: function () {
			var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
			var mq = function (query) {
				return window.matchMedia(query).matches;
			}

			if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
				return true;
			}

			// include the 'heartz' as a way to have a non matching MQ to help terminate the join
			// https://git.io/vznFH
			var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
			return mq(query);
		}
	});

	// Registration jQuery Plugin
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName,
                    new NavigationDesktopMenu(this, options));
            }
        });
    };

})(jQuery, window, document);