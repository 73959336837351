import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { I18n } from "@lingui/react";
import { Trans, t } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { Row, Col, Button } from 'reactstrap';
import SearchFieldMultiselect from '../searchfield/SearchFieldMultiselect';

const SerieSearchFields = (props) => {
    return (
        <I18n>
            {({ i18n }) => (
                <Fragment>
                    <Row className="mx-n1">
                        <Col md="6" className="mb-1 px-1">
                            <SearchFieldMultiselect
                                enableSearch
                                name="einbruchschutz"
                                selectedValues={props.filterValues.einbruchschutz}
                                items={props.searchFieldData.einbruchschutz}
                                onSelectionChanged={(sel) => props.onSelectionChanged({ einbruchschutz: sel })}
                                placeholder={i18n._(t('serien.einbruchschutz')`Einbruchschutz...`)}
                            />
                        </Col>
                        <Col md="6" className="mb-2 px-1">
                            <SearchFieldMultiselect
                                enableSearch
                                name="feuerschutz"
                                selectedValues={props.filterValues.feuerschutz}
                                items={props.searchFieldData.feuerschutz}
                                onSelectionChanged={(sel) => props.onSelectionChanged({ feuerschutz: sel })}
                                placeholder={i18n._(t('serien.feuerschutz')`Feuerschutz...`)}
                            />
                        </Col>
                        <Col md="6" className="mb-2 px-1">
                            <SearchFieldMultiselect
                                enableSearch
                                name="kategorie"
                                selectedValues={props.filterValues.kategorie}
                                items={props.searchFieldData.kategorie}
                                onSelectionChanged={(sel) => props.onSelectionChanged({ kategorie: sel })}
                                placeholder={i18n._(t('serien.kategorie')`Kategorie...`)}
                            />
                        </Col>
                        <Col md="6" className="mb-2 px-1">
                            <SearchFieldMultiselect
                                enableSearch
                                name="versicherungssumme"
                                selectedValues={props.filterValues.versicherungssumme}
                                items={props.searchFieldData.versicherungssumme}
                                onSelectionChanged={(sel) => props.onSelectionChanged({ versicherungssumme: sel })}
                                placeholder={i18n._(t('serien.versicherungssumme')`Versicherungsumme...`)}
                            />
                        </Col>
                        <Col md="6" className="mb-2 px-1">
                            <SearchFieldMultiselect
                                enableSearch
                                name="wertsachenGegenstand"
                                selectedValues={props.filterValues.wertsachenGegenstand}
                                items={props.searchFieldData.wertsachenGegenstand}
                                onSelectionChanged={(sel) => props.onSelectionChanged({ wertsachenGegenstand: sel })}
                                placeholder={i18n._(t('serien.wertsachenGegenstand')`Wertsachen / Gegenstand...`)}
                            />
                        </Col>
                        <Col md="6" className="mb-2 px-1">
                            <div className="">
                                <Button color="secondary" onClick={props.onResetFilters}><FontAwesomeIcon icon={faTrashAlt} className="mr-2" /><Trans id="serien.resetFilters">Alle Filter löschen</Trans></Button>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </I18n>
    );
};

SerieSearchFields.propTypes = {
    filterValues: PropTypes.object,
    onResetFilters: PropTypes.func,
    onSelectionChanged: PropTypes.func
};

export default SerieSearchFields;